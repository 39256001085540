import React from "react";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from 'redux-form';

const StepTwo = () => {
   const dispatch = useDispatch();
   const performanceGuarantee = useSelector(state => state.performanceGuarantee);
   const securityDeposit = useSelector(state => state.securityDeposit);
   const selectedModesOfPayment = useSelector(state => state.selectedModesOfPayment || []); // Provide a default empty array

   const handlePerformanceGuaranteeChange = (e) => {
   //   dispatch(updatePerformanceGuarantee(e.target.value));
   };

   const handleSecurityDepositChange = (e) => {
   //   dispatch(updateSecurityDeposit(e.target.value));
   };

const handleModeOfPaymentChange = (e) => {
  const selectedMode = e.target.value;
  let updatedModesOfPayment = [...selectedModesOfPayment];

  if (e.target.checked) {
    updatedModesOfPayment.push(selectedMode);
  } else {
    updatedModesOfPayment = updatedModesOfPayment.filter(mode => mode !== selectedMode);
  }
//   dispatch(updateModeOfPayment(updatedModesOfPayment));
};

const ScheduleInputField = ({ label, name }) => (
   <div className="col-lg-6 mb-2">
     <div className="form-group mb-3">
       <label className="text-label">{label}</label>
       <Field
         name={name}
         component="input"
         type="text"
         placeholder={`Enter ${label}`}
         className="redux-form-input form-control form-control-sm"
       />
     </div>
   </div>
 );



   const clause1=() =>{
      return(<>
         <div className="col-lg-12 mb-2">
            <strong>PROFORMA OF SCHEDULES</strong>
           </div>
         <div className="col-lg-6 mb-2">

         <div className="form-group mb-3">
         <label className="text-label">SCHEDULE 'A'</label>
         <Field name="schedule_a_file" component="input" type="file" className="redux-form-input form-control form-control-sm" />
       </div>
       </div>
       <div className="col-lg-6 mb-2">

         <div className="form-group mb-3">
         <label className="text-label">SCHEDULE 'B'</label>
         <Field name="schedule_a_file" component="input" type="file" className="redux-form-input form-control form-control-sm" />
       </div>
       </div>
       <div className="col-lg-6 mb-2">
         <div className="form-group mb-3">
         <label className="text-label">SCHEDULE 'C'</label>
         <Field name="schedule_a_file" component="input" type="file" className="redux-form-input form-control form-control-sm" />
       </div>
       </div>
       <div className="col-lg-6 mb-2">
         <div className="form-group mb-3">
         <label className="text-label">SCHEDULE 'D'</label>
         <Field name="schedule_a_file" component="input" type="file" className="redux-form-input form-control form-control-sm" />
       </div>
       </div>
       <div className="col-lg-6 mb-2">
         <div className="form-group mb-3">
         <label className="text-label">SCHEDULE 'E'</label>
         <Field name="schedule_a_file" component="input" type="file" className="redux-form-input form-control form-control-sm" />
       </div>
       </div>
       <div className="col-lg-6 mb-2">
      </div>
       <div className="col-lg-6 mb-2">
       <div className="form-group mb-3">
         <label className="text-label">Performance Guarantee:</label>
      <Field type="text" component="input" value={performanceGuarantee} onChange={handlePerformanceGuaranteeChange} className="redux-form-input form-control form-control-sm" />
      </div>
      </div>

      <div className="col-lg-6 mb-2">
      <div className="form-group mb-3">
               <label className="text-label">Security Deposit:</label>
            <Field type="text" component="input" value={securityDeposit} onChange={handleSecurityDepositChange} className="redux-form-input form-control form-control-sm" />
      </div>
      </div>
      <div className="col-lg-6 mb-2">
      <label className="text-label">Mode of Payment</label>

      <div className="form-group mb-3">
      <div className="form-check form-check-inline">
        <Field
          type="checkbox"
          value="BG"
          name="bg"
          component="input"
          // checked={selectedModesOfPayment.includes("BG")}
          onChange={handleModeOfPaymentChange}
          className="redux-form-input form-check-input"
        />
        <label className="form-check-label">
        BG
      </label>
      </div>
      <div className="form-check form-check-inline">
        <Field
         name="epbg"
         component="input"
          type="checkbox"
          value="EPBG"
          // checked={selectedModesOfPayment.includes("EPBG")}
          onChange={handleModeOfPaymentChange}
          className="redux-form-input form-check-input"
        />
              <label className="form-check-label">
        EPBG
      </label>

         </div>
      <div className="form-check form-check-inline">
        <Field
         name="dd"
         component="input"
          type="checkbox"
          value="DD"
          // checked={selectedModesOfPayment.includes("EPBG")}
          onChange={handleModeOfPaymentChange}
          className="redux-form-input form-check-input"
        />
              <label className="form-check-label">
              DD
      </label>
         </div>
      <div className="form-check form-check-inline">
        <Field
         name="bond"
         component="input"
          type="checkbox"
          value="BOND"
          // checked={selectedModesOfPayment.includes("EPBG")}
          onChange={handleModeOfPaymentChange}
          className="redux-form-input form-check-input"
        />
              <label className="form-check-label">
              BOND
      </label>
         </div>
      <div className="form-check form-check-inline">
        <Field
         name="fdr"
         component="input"
          type="checkbox"
          value="FDR"
          // checked={selectedModesOfPayment.includes("EPBG")}
          onChange={handleModeOfPaymentChange}
          className="redux-form-input form-check-input"
        />
              <label className="form-check-label">
              FDR
      </label>
         </div>
         <div className="form-check form-check-inline">
        <Field
         name="other"
         component="input"
          type="checkbox"
          value="Other"
          // checked={selectedModesOfPayment.includes("Other")}
          onChange={handleModeOfPaymentChange}
          className="redux-form-input form-check-input"
        />
              <label className="form-check-label">
              Other
      </label>
         </div>

         </div>
         </div>

         <div className="col-lg-6 mb-2">
      <div className="form-group mb-3">

      {selectedModesOfPayment.includes("Other") && (
        <Field
          type="textarea"
          name="other_payment_mode"
          placeholder="Comment for Other" className="redux-form-input form-control form-control-sm"
          // Implement onChange handler and dispatch action to update state
        />
      )}
</div>
</div>
{/* <div className="row"> */}
   {/* <label>(a). Schedule/statement for determining theoretical quantity of cement & bitumen on the basis of Delhi Schedule of Rates .................. printed by C.P.W.D</label> */}
        {/* <ScheduleInputField
          label="Schedule/statement for determining theoretical quantity of cement & bitumen on the basis of Delhi Schedule of Rates printed by C.P.W.D"
          name="cement_bitumen_quantity"
        /> */}
        {/* <ScheduleInputField
          label="Mention % as per real-time basis"
          name="real_time_percentage"
        />
        <ScheduleInputField
          label="Input Taxt in %"
          name="input_tax_percentage"
        />
        <ScheduleInputField
          label="Mention %"
          name="mention_percentage"
        /> */}
      {/* </div> */}
      <div className="col-lg-6 mb-2">
       <div className="form-group mb-3">
         <label className="text-label">(a). Schedule/statement for determining theoretical quantity of cement & bitumen on the basis of Delhi Schedule of Rates ..... printed by C.P.W.D</label>
      <Field type="text" component="input" name="schedule_statement" value={performanceGuarantee} onChange={handlePerformanceGuaranteeChange} className="redux-form-input form-control form-control-sm" />
      </div>
      </div>
      <div className="col-lg-6 mb-2">
       <div className="form-group mb-3">
         <label className="text-label">Threshold value (Estimated cost put to tender) at and above which Integrity Pact would be applicable</label>
      <Field type="number" component="input" name="estimated_cost" value="" onChange="" className="redux-form-input form-control form-control-sm" />
      </div>
      </div>


       </>
      );

   }
   const defaultAccordion = [
      {
        title: "CPWD - 7/8",
        text:clause1(),
        bg: "primary",

      },
      {
        title: "Clause 1",
        text:
          "(i) : Performance Guarantee",

        bg: "info",

      },
      {
        title: "Clause 2 : Compensation for Delay",
        text:
          "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

        bg: "success",

      },
      {
         title: "Clause 2A",
         text:
           "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

         bg: "success",

       },
       {
         title: "Clause 5 : Time and Extension for Delay         ",
         text:
           "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

         bg: "success",

       },
      //  {
      //    title: "Clause 2 : Compensation for Delay",
      //    text:
      //      "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

      //    bg: "success",

      //  },

    ];

   return (
      <section>
          <Col xl="12">
          <Card>
            {/* <Card.Header className="d-block">
              <Card.Title>Accordion Header Background</Card.Title>
              <Card.Text className="m-0 subtitle">
                Add <code>accordion-header-bg</code> class with{" "}
                <code>accordion</code>
              </Card.Text>
            </Card.Header> */}
            <Card.Body>
            <form onSubmit="">

            <Accordion className="accordion accordion-primary" defaultActiveKey="0">
					{defaultAccordion.map((d, i) => (
					  <Accordion.Item className="accordion-item" key={i} eventKey={`${i}`}>
						<Accordion.Header className="accordion-header rounded-lg">
							{d.title}
						</Accordion.Header>
						<Accordion.Collapse eventKey={`${i}`}>
							<div className="accordion-body">
                     <div className="row">

                        {d.text}
                        </div>
                        </div>
                     {/* {i === 0 ? <div className="accordion-body">{d.text}</div>






                     :
                      (i === 1 ? <div className="accordion-body">{d.text}</div> : null)
                     } */}

						</Accordion.Collapse>
					  </Accordion.Item>
					))}
				</Accordion>
        </form>
            </Card.Body>
          </Card>
        </Col>
         {/* <div className="row">
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Company Name*</label>
                  <input
                     type="text"
                     name="firstName"
                     className="form-control form-control-sm"
                     placeholder="Cellophane Square"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Company Email Address*</label>
                  <input
                     type="email"
                     className="form-control form-control-sm"
                     id="emial1"
                     placeholder="example@example.com.com"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Company Phone Number*</label>
                  <input
                     type="text"
                     name="phoneNumber"
                     className="form-control form-control-sm"
                     placeholder="(+1)408-657-9007"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">
                     Your position in Company*
                  </label>
                  <input
                     type="text"
                     name="place"
                     className="form-control form-control-sm"
                     required
                  />
               </div>
            </div>
         </div> */}
      </section>
   );
};

export default reduxForm({
   form: 'StepTwo', // a unique identifier for this form
 })(StepTwo);
