import React, { Fragment, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import PageTitle from "../../layouts/PageTitle";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";

const Index = (props) => {
	// const step = props.step;
	// console.log(step)
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	// Get specific query parameters
	const step = searchParams.get('step')??0;

	const [goSteps, setGoSteps] = useState(step);
	const clause1=() =>{
return(<>
<StepOne />	</>)

	}
	const tabData = [
		{
		  name: "Basic Nit",
		  icon: "info-circle",
		  content:clause1()
		},
		{
		  name: "Single Stage",
		  icon: "wpforms",
		  content:
			"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
		},
		{
		  name: "2 or 3 Bid Stage",
		  icon: "list",
		  content:
			"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
		},
		{
		  name: "Scheudule and Clause",
		  icon: "clock",
		  content:
			<StepTwo/>,
		},

		// {
		//   name: "Message",
		//   icon: "envelope",
		//   content:
		// 	"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
		// },
	  ];

	return (
		<Fragment>
			<PageTitle activeMenu="Section-layout" motherMenu="NIT" />

			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Form step </h4>
						</div>
						<div className="card-body">
						<div className="custom-tab-1">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} me-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {/* <h4>This is {data.name} title</h4> */}
                        {/* <p>{data.content}</p> */}
                        <p>{data.content}</p>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
							{/* <div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper>
							  {goSteps == 0 && (
								<>
									<StepOne />
									<div className="text-end toolbar toolbar-bottom p-2">
										{
											// step==0?
											// <>
											// 										<Link  className="btn btn-primary sw-btn-next" to="/pending-nit">Next</Link>

											// </>
											// :
											// <>
																					<button  className="btn btn-primary sw-btn-next" onClick={() => setGoSteps(1)}>Next</button>

											// </>
										}
									</div>
								</>
							  )}
							  {goSteps == 1 && (
								<>
									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>
								</>
							  )}
							  {goSteps == 2 && (
								<>
									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>
								</>
							  )}
							  {goSteps == 3 && (
								<>
									<StepFour />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(2)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(4)}>Submit</button>
									</div>
								</>
							  )}

							</div> */}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Index;
