 import React from "react";
import { APP_NAME,API_BASE_URL } from "../../config/constants";
const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright ©
           {/* Designed &amp; Developed by{" "} */}
           
          <a href={API_BASE_URL} target="_blank" rel="noreferrer">
            {APP_NAME}
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
