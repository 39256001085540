import React, { useState } from "react";
import { Field, reduxForm } from 'redux-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomClearIndicator from "../../PluginsMenu/Select2/MultiSelect";

const StepOne = (props) => {
   const { handleSubmit } = props;
   const [showPreBidMeetingVenue, setShowPreBidMeetingVenue] = useState(false);
   const [showPreBidMeetingLocation, setShowPreBidMeetingLocation] = useState(false);

   const handlePreBidMeetingChange = (event) => {
     setShowPreBidMeetingVenue(event.target.value === 'yes');
     setShowPreBidMeetingLocation(event.target.value === 'yes');
   };
   const options = [
      { value: "Single work order of x amount", label: "Single work order of x amount" },
      { value: "Two work order of y amount", label: "Two work order of y amount" },
      { value: "Three work Order of Z amount", label: "Three work Order of Z amount" },
    ];


    return (
      <form onSubmit="">

      <section>
         <div className="row">
            {/* <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Name of the work<span className="text-danger">*</span></label>
                  <Field name="nameOfWork" component="textarea" type="text" placeholder="Name of the work" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">NIT Approving Authority<span className="text-danger">*</span></label>
                  <Field name="nameOfWork" component="textarea" type="text" placeholder="NIT Approving Authority" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div> */}

            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">NIT/RFP No.<span className="text-danger">*</span></label>
                  {/*<Field name="nitRepNo" component="input" readOnly type="text" placeholder="0HDD2024" className="redux-form-input form-control form-control-sm readonly"/>*/}
                   <h4 class="mt-0 text-muted">NIT/RFP2024/56/ABCR/2024</h4>
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Name of Tender/Work<span className="text-danger">*</span></label>
                  <Field name="nameOfTender" component="input"  type="text" placeholder="Name of Tender/Work" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Sub Work/Packages<span className="text-danger">*</span></label>
                  <Field name="subWorkPackages" component="input"  type="text" placeholder="Sub Work/Packages" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">BID Value<span className="text-danger">*</span></label>
                  <Field name="bid_value" component="input"  type="text" placeholder="BID Value" className="redux-form-input form-control form-control-sm"/>
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Tender Inviting Authority<span className="text-danger">*</span></label>
                  <Field name="inviting_authority" component="input"  type="text" placeholder="Tender Inviting Authority" className="redux-form-input form-control form-control-sm"/>
               </div>
            </div>
            <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Work Location<span className="text-danger">*</span></label>
            <Field name="work_location" component="input" type="text" placeholder="Work Location" className="redux-form-input form-control form-control-sm" />
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Tender Type<span className="text-danger">*</span></label>
            <Field name="tender_type" component="select" className="redux-form-input form-control form-control-sm">
              <option value="">Select Competitive Bidding Type</option>
              <option value="open">Open</option>
              <option value="limited">Limited</option>
            </Field>          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Competitive Bidding Type<span className="text-danger">*</span></label>
            <Field name="competitive_bidding_type" component="select" className="redux-form-input form-control form-control-sm">
              <option value="">Select Competitive Bidding Type</option>
              <option value="ncb">NCB</option>
              <option value="icb">ICB</option>
            </Field>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Category of Tender<span className="text-danger">*</span></label>
            <Field name="tender_category" component="select" className="redux-form-input form-control form-control-sm">
              <option value="">Select Category of Tender</option>
              <option value="Civil">Civil</option>
              <option value="Electrical">Electrical</option>
              <option value="Horticulture">Horticulture</option>
              <option value="Specialized items/jobs">Specialized items/jobs</option>
            </Field>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Procurement Type<span className="text-danger">*</span></label>
            <Field name="procurement_type" component="select" className="redux-form-input form-control form-control-sm">
              <option value="">Select Procurement Type</option>
              <option value="Contract">Contract</option>
              <option value="Services">Services</option>
              <option value="Maintenance">Maintenance</option>
              <option value="Upgradation">Upgradation</option>
            </Field>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Bid Type<span className="text-danger">*</span></label>
            <Field name="bid_type" component="select" className="redux-form-input form-control form-control-sm">
              <option value="">Select Bid Type</option>
              <option value="Percentage Rate">Percentage Rate</option>
              <option value="Item Rate">Item Rate</option>
              <option value="EPC">EPC</option>
              <option value="EOI">EOI</option>
              <option value="Lumpsum">Lumpsum</option>
              <option value="Composite">Composite</option>
            </Field>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Procurement Category<span className="text-danger">*</span></label>
            <Field name="procurement_category" component="select" className="redux-form-input form-control form-control-sm">
              <option value="">Select Procurement Category</option>
              <option value="Goods">Goods</option>
              <option value="Works">Works</option>
              <option value="Services">Services</option>
            </Field>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Time Allowed for Work (Days)<span className="text-danger">*</span></label>
            <Field name="time_allowed" component="input" type="number" placeholder="Number of Days" className="redux-form-input form-control form-control-sm" />
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Bid Publish Date & Time<span className="text-danger">*</span></label>
            <Field
              name="bid_publish_date" type="date" component="input" className="redux-form-input form-control form-control-sm"
            //   render={({ input }) => (
            //     <DatePicker
            //       selected={input.value}
            //       onChange={(date) => input.onChange(date)}
            //       showTimeSelect
            //       timeFormat="HH:mm"
            //       timeIntervals={15}
            //       dateFormat="yyyy-MM-dd HH:mm"
            //       className="form-control form-control-sm"
            //     />
            //   )}
            />
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Bid Submission Closing Date & Time<span className="text-danger">*</span></label>
            <Field
              name="bid_submission_closing_date"
              type="date" component="input" className="redux-form-input form-control form-control-sm"
            //   render={({ input }) => (
            //     <DatetimePicker
            //       onChange={(value) => input.onChange(value)}
            //       value={input.value}
            //     />
            //   )}
            />
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Bid Opening Dates & TIme<span className="text-danger">*</span></label>
            <Field
              name="bid_opning_datetime"
              type="date" component="input" className="redux-form-input form-control form-control-sm"
            //   render={({ input }) => (
            //     <DatetimePicker
            //       onChange={(value) => input.onChange(value)}
            //       value={input.value}
            //     />
            //   )}
            />
          </div>
        </div>

        {/* <div className="col-lg-6 mb-2">
        <div className="form-group mb-3">
          <label className="text-label">Date & Time of Pre-bid Meeting</label>
          <Field
            name="pre_bid_meeting_datetime"
            component={showPreBidMeetingLocation ? 'date' : 'input'}
            type="datetime-local"
            className="redux-form-input form-control form-control-sm"
          />
        </div>
      </div> */}



      {/* Pre-bid Meeting Selection */}
      <div className="form-group">
        <label className="text-label">Pre-bid Meeting</label>
        {/* <div className="form-check">
                                      <Field name="pre_bid_meeting" className="form-check-input" component="input" type="radio" value="yes" onChange={handlePreBidMeetingChange} />

                          <label className="form-check-label">
                          {' '} Yes                          </label>
                        </div>
                        <div className="form-check">
                                      <Field name="pre_bid_meeting" className="form-check-input" component="input" type="radio" value="no" onChange={handlePreBidMeetingChange} />

                          <label className="form-check-label">
                            No
                          </label>
                        </div> */}


                  <div className="form-group mb-0">
                  <label className="radio-inline me-3">

            <Field name="pre_bid_meeting" className="" component="input" type="radio" value="yes" onChange={handlePreBidMeetingChange} />
 {' '} Yes
          </label>
          <label className="radio-inline ">

            <Field name="pre_bid_meeting" className="" component="input" type="radio" value="no" onChange={handlePreBidMeetingChange} />
 {' '} No
          </label>
        </div>
      </div>
      {showPreBidMeetingLocation && (

      <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Date & Time of Pre-bid Meeting<span className="text-danger">*</span></label>
            <Field
              name="bid_opning_datetime"
              type="date" component="input" className="redux-form-input form-control form-control-sm"
            //   render={({ input }) => (
            //     <DatetimePicker
            //       onChange={(value) => input.onChange(value)}
            //       value={input.value}
            //     />
            //   )}
            />
          </div>
        </div>
             )}

      {/* Pre-bid Meeting Location */}
      {showPreBidMeetingLocation && (
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Pre-bid Meeting Location</label>
            <Field
              name="pre_bid_meeting_location"
              component="input"
              type="text"
              className="redux-form-input form-control form-control-sm"
            />
          </div>
        </div>
      )}


      {/* Pre-bid Meeting Venue */}
      {showPreBidMeetingVenue && (
                 <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">

          <label className="text-label">Pre-bid Meeting Venue</label>
          <Field
            name="pre_bid_meeting_venue"
            component="input"
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter venue"
          />
        </div>
        </div>
      )}
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">

        <label className="text-label">Cover Selection for Stage</label>
        <Field name="cover_selection" component="select" className="form-control form-control-sm">
          <option value="">Select Option</option>
          <option value="single_bid">Single Bid System</option>
          <option value="two_bid">Two Bid System</option>
          <option value="three_bid">Three Bid System</option>
        </Field>
</div>
</div>

<div className="col-lg-6 mb-2">
          <div className="form-group mb-3">

        <label className="text-label">No. of Stages</label>
        <Field name="num_stages" component="select" className="form-control form-control-sm">
          <option value="">Select Option</option>
          <option value="single_stage">Single</option>
          <option value="two_stage">Two</option>
          <option value="three_stage">Three</option>
        </Field>
      </div>
      </div>

      {/* Tender Notice Type */}
      <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">

        <label className="text-label">Tender Notice Type</label>
        <Field name="notice_type" component="select" className="form-control form-control-sm">
          <option value="">Select Option</option>
          <option value="standard">Standard Notice type</option>
          <option value="custom">Notice type</option>
        </Field>
</div>
</div>
<div className="col-lg-6 mb-2">
          <div className="form-group">
        <label className="text-label">Receipt of Queries through</label>

<div className="row">
        <div className="col-lg-6">
          <label>
            Interface
          </label>
          <Field name="query_receipt" component="input" type="text" value="interface" className="form-control form-control-sm" />
          </div>
          <div className="col-lg-6">

          <label>
            Email
          </label>
          <Field name="query_receipt" component="input" type="text" value="email" className="form-control form-control-sm" />
</div>
      </div>
</div>
</div>
<div className="col-lg-6 mb-2">
<div className="form-group mb-3">
        <label className="text-label">Bid Validity Period</label>
        <Field name="bid_validity_period" component="select" className="form-control form-control-sm">
          <option value="">Select Bid Validity Period</option>
          <option value="30">30 Days for Single Stage After Opening of technical Bid</option>
          <option value="75">75 Days for 2/3 Stage After Opening of technical Bid</option>
        </Field>

</div>
</div>
<div className="col-lg-6 mb-2">
<div className="form-group mb-3">
        <label className="text-label">Publicity of Tender (Days)</label>
        <Field
          name="publicity_of_tender"
          component="input"
          type="number"
          className="form-control form-control-sm"
          min="0" // Set minimum value allowed
        />
      </div>

</div>
{/* <div className="form-group">
        <label className="text-label">Estimated Contract Value (ECV)</label>
        <Field
          name="ecv"
          component="input"
          type="number"
          className="form-control form-control-sm"
         //  onChange={handleEcvChange}
        />
      </div> */}

      {/* EMD Percentage */}
      {/* <div className="form-group">
        <label className="text-label">EMD Percentage (%)</label>
        <Field
          name="emd_percentage"
          component="input"
          type="number"
          className="form-control form-control-sm"
         //  onChange={handleEmdPercentageChange}
        />
      </div> */}

      {/* EMD Amount */}
      <div className="col-lg-6 mb-2">
<div className="form-group mb-3">
        <label className="text-label">EMD Amount</label>
        <input
          name="emd_amount"
          type="text"
          className="form-control form-control-sm"
         //  value={calculateEmdAmount()}
          readOnly
        />
      </div>
      </div>

      {/* EMD in Favor of */}
      <div className="col-lg-6 mb-2">
<div className="form-group mb-3">
        <label className="text-label">EMD in Favor of</label>
        <Field
          name="emd_in_favor_of"
          component="input"
          type="text"
          className="form-control form-control-sm"
        />
      </div>

</div>
<div className="col-lg-6 mb-4">
<div className="form-group">
        <label className="text-label">Selection of Eligibility</label>
        <Field name="eligibility" component="select" className="form-control form-control-sm">
          <option value="">Select Eligibility</option>
          <option value="CPWD Contractor">CPWD Contractor (Always taken)</option>
          <option value="NON CPWD Contractor">NON CPWD Contractor</option>
          <option value="Both">Both</option>
          <option value="Other Body vendor">Other Body vendor (Give List)</option>
        </Field>
      </div>

</div>
<div className="col-lg-6 mb-2">
<div className="form-group">
        <label className="text-label">Eligibility Criteria for Technical Eligibility</label>
        <CustomClearIndicator options={options}></CustomClearIndicator>

        {/* <Field
          name="technicalEligibility"
          component="select"
          className="form-control form-control-sm"
          multiple
        >
          <option value="Single work order of x amount">Single work order of x amount</option>
          <option value="Two work order of y amount">Two work order of y amount</option>
          <option value="Three work Order of Z amount">Three work Order of Z amount</option>
        </Field> */}
      </div>


</div>
<div className="col-lg-6 mb-2">

<div className="form-group">
        <label className="text-label">Type of Contract</label>
        <Field
          name="contractType"
          component="select"
          className="form-control form-control-sm"
        >
          <option value="Non-EPC Contract">Non-EPC Contract</option>
          <option value="EPC Contract">EPC Contract</option>
        </Field>
      </div>
</div>
      {/* Type of Work */}
      <div className="col-lg-6 mb-2">

      <div className="form-group">
        <label className="text-label">Type of Work</label>
        <Field
          name="workType"
          component="select"
          className="form-control form-control-sm"
        >
          <option value="Normal Standalone Works">Normal Standalone Works</option>
          <option value="Composite works">Composite works</option>
          <option value="Specialized Works">Specialized Works</option>
        </Field>
      </div>
</div>


         </div>
      </section>
      </form>
   );
};

// export default StepOne;
export default reduxForm({
   form: 'StepOne', // a unique identifier for this form
 })(StepOne);
