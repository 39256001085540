import React, { useState } from "react";
import { Field, reduxForm } from 'redux-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomClearIndicator from "../PluginsMenu/Select2/MultiSelect";

const StepOne = (props) => {
   const { handleSubmit } = props;
   const [showPreBidMeetingVenue, setShowPreBidMeetingVenue] = useState(false);
   const [showCoverSelectionVenue, setCoverSelectionVenue] = useState('');
   const [showPreBidMeetingLocation, setShowPreBidMeetingLocation] = useState(false);

   const handlePreBidMeetingChange = (event) => {
     setShowPreBidMeetingVenue(event.target.value === 'yes');
     setShowPreBidMeetingLocation(event.target.value === 'yes');
   };
   const handleCoverSelectionChange = (event) => {
    setCoverSelectionVenue(event.target.value);
    //  setShowPreBidMeetingLocation(event.target.value === 'yes');
   };
   const options = [
      { value: "Single work order of x amount", label: "Single work order of x amount" },
      { value: "Two work order of y amount", label: "Two work order of y amount" },
      { value: "Three work Order of Z amount", label: "Three work Order of Z amount" },
    ];
    const standardNoticeTenderPeriods = [
        { duration: '14 Days' },
        { duration: '7 Days' }
      ];
      const DemoComponent = () => {
        // Sample data array
        const demoArray = [
          { label: 'Discipline', value: 'Content coming from SAP' },
          { label: 'Division', value: 'Content coming from SAP' },
          { label: 'Engineer', value: 'Content coming from SAP' },
          { label: 'Circle', value: 'Content coming from SAP' },
          { label: 'Office', value: 'Content coming from SAP' },
          { label: 'BID Value', value: 'Content coming from SAP' },
          { label: 'SOR', value: 'Content coming from SAP' },
          { label: 'BOQ Changes', value: 'Content coming from SAP' },
        //   { label: 'Major/Minor decision', value: 'Dependent on material types' },
        //   { label: 'WORK LOCATION', value: 'FIX or Manual Entry' }
        ];
      
        return (
            <div className="row">
              {demoArray.map((item, index) => (<>
                                     <div className="clearfix"></div>

                                     <div class="col-4 mt-2">
                                         <h5 class="f-w-500"> {item.label}<span class="pull-right">:</span></h5>
                                     </div>
                                     <div class="col-8 mt-2"><span className="font-w400">{item.value}</span></div>
                {/* // <div key={index} className="col-4 mb-2">
                //   <label >{item.label}:</label>
                //   <strong>{item.value}</strong>
                // </div> */}
                </> ))}
            </div>
        );
      };
      
    return (
      <form onSubmit="">
      <section>
          <div className="row pb-4 border-bottom">
              <div className="col-xxl-6 col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                 <div className="row">
                     <div class="col-4 mb-3">
                         <h5 class="f-w-500 mt-2"> Project ID<span class="pull-right">:</span><span className="text-danger">*</span></h5>
                     </div>
                     <div className="col-8 mb-3">
                             <Field name="tender_type" component="select" className="col-auto w-auto redux-form-input form-control form-control-sm form-control form-control-sm-sm">
                                 <option value="">Select Competitive Bidding Type</option>
                                 <option value="open">Open</option>
                                 <option value="limited">Limited</option>
                             </Field>
                     </div>
                     <div className="clearfix"></div>

                     <div class="col-4 mt-2">
                         <h5 class="f-w-500"> A/A & E/S Ref Number<span class="pull-right">:</span></h5>
                     </div>
                     <div class="col-8 mt-2"><span className="font-w400">100000025</span></div>

                     <div className="clearfix"></div>

                     <div class="col-4 mt-2">
                         <h5 class="f-w-500"> A/A & E/S Date<span class="pull-right">:</span></h5>
                     </div>
                     <div class="col-8 mt-2"><span className="font-w400">01.01.2024</span></div>

                     <div className="clearfix"></div>

                     <div class="col-4 mt-2">
                         <h5 class="f-w-500">A/A & E/S Amount<span class="pull-right">:</span></h5>
                     </div>
                     <div class="col-8 mt-2"><span className="font-w400">₹10,000</span></div>

                     <div className="clearfix"></div>

                     <div class="col-4 mt-2">
                         <h5 class="f-w-500"> Requisition Number<span class="pull-right">:</span></h5>
                     </div>
                     <div class="col-8 mt-2"><span className="font-w400">100000026</span></div>
                 </div>
              </div>


              <div className="col-xxl-6 col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                      <div class="col-4 mb-3">
                          <h5 class="f-w-500 mt-2"> Package<span class="pull-right">:</span><span className="text-danger">*</span></h5>
                      </div>
                      <div className="col-8 mb-3">
                          <Field name="tender_type" component="select" className="col-auto w-auto redux-form-input form-control form-control-sm form-control form-control-sm-sm">
                              <option value="">Select Competitive Bidding Type</option>
                              <option value="open">Open</option>
                              <option value="limited">Limited</option>
                          </Field>
                      </div>
                      <div className="clearfix"></div>

                      <div class="col-4 mt-2">
                          <h5 class="f-w-500"> Location of work<span class="pull-right">:</span></h5>
                      </div>
                      <div class="col-8 mt-2"><span className="font-w400">Jodhpur</span></div>

                      <div className="clearfix"></div>

                      <div class="col-4 mt-2">
                          <h5 class="f-w-500"> Name of the work<span class="pull-right">:</span></h5>
                      </div>
                      <div class="col-8 mt-2"><span className="font-w400">Construction at OSIA,</span></div>

                      <div className="clearfix"></div>


                      <div class="col-4 mt-2">
                          <h5 class="f-w-500"> NIT Approving Authority<span class="pull-right">:</span></h5>
                      </div>
                      <div class="col-8 mt-2"><span className="font-w400">Mr. ABC</span></div>

                      <div className="clearfix"></div>

                      <div class="col-4 mt-2">
                          <h5 class="f-w-500">BID Value<span class="pull-right">:</span></h5>
                      </div>
                      <div class="col-8 mt-2"><span className="font-w400">₹10,000,00</span></div>
                  </div>
              </div>


          </div>



        <h4 className="mt-4">Section : 1</h4>
        <p>The Basic NIT Tender Information for Section 1</p>
         <div className="row border-bottom pb-4 ">
            {/* <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Name of the work<span className="text-danger">*</span></label>
                  <Field name="nameOfWork" component="textarea" type="text" placeholder="Name of the work" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">NIT Approving Authority<span className="text-danger">*</span></label>
                  <Field name="nameOfWork" component="textarea" type="text" placeholder="NIT Approving Authority" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div> */}

            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">NIT/RFP No.<span className="text-danger">*</span></label>
                   <h4 className="mt-0 text-muted">0HDD2024/56/ABCR/2024</h4>

                  {/*<Field name="nitRepNo" component="input" readOnly type="text" placeholder="0HDD2024" className="redux-form-input form-control form-control-sm"/>*/}

               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Name of Tender/Work<span className="text-danger">*</span></label>
                  <Field name="nameOfTender" component="input"  type="text" placeholder="Name of Tender/Work" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Sub Work/Packages<span className="text-danger">*</span></label>
                  <Field name="subWorkPackages" component="input"  type="text" placeholder="Sub Work/Packages" className="redux-form-input form-control form-control-sm"/>

               </div>
            </div>
             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Tender Type<span className="text-danger">*</span></label>
                     <Field name="tender_type" component="select" className="redux-form-input form-control form-control-sm">
                         <option value="">Select Competitive Bidding Type</option>
                         <option value="open">Open</option>
                         <option value="limited">Limited</option>
                     </Field>
                 </div>
             </div>
             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Competitive Bidding Type<span className="text-danger">*</span></label>
                     <Field name="competitive_bidding_type" component="select" className="redux-form-input form-control form-control-sm">
                         <option value="">Select Competitive Bidding Type</option>
                         <option value="ncb">NCB</option>
                         <option value="icb">ICB</option>
                     </Field>
                 </div>
             </div>

             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Category of Tender<span className="text-danger">*</span></label>
                     <Field name="tender_category" component="select" className="redux-form-input form-control form-control-sm">
                         <option value="">Select Category of Tender</option>
                         <option value="Civil">Civil</option>
                         <option value="Electrical">Electrical</option>
                         <option value="Horticulture">Horticulture</option>
                         <option value="Specialized items/jobs">Specialized items/jobs</option>
                     </Field>
                 </div>
             </div>

             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Procurement Type<span className="text-danger">*</span></label>
                     <Field name="procurement_type" component="select" className="redux-form-input form-control form-control-sm">
                         <option value="">Select Procurement Type</option>
                         <option value="Contract">Contract</option>
                         <option value="Services">Services</option>
                         <option value="Maintenance">Maintenance</option>
                         <option value="Upgradation">Upgradation</option>
                     </Field>
                 </div>
             </div>
             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Bid Type<span className="text-danger">*</span></label>
                     <Field name="bid_type" component="select" className="redux-form-input form-control form-control-sm">
                         <option value="">Select Bid Type</option>
                         <option value="Percentage Rate">Percentage Rate</option>
                         <option value="Item Rate">Item Rate</option>
                         <option value="EPC">EPC</option>
                         <option value="EOI">EOI</option>
                         <option value="Lumpsum">Lumpsum</option>
                         <option value="Composite">Composite</option>
                     </Field>
                 </div>
             </div>

             {/* Type of Work */}
             <div className="col-lg-6 mb-2">

                 <div className="form-group">
                     <label className="text-label">Type of Work</label>
                     <Field
                         name="workType"
                         component="select"
                         className="form-control form-control-sm"
                     >
                         <option value="Normal Standalone Works">Normal Standalone Works</option>
                         <option value="Composite works">Composite works</option>
                         <option value="Specialized Works">Specialized Works</option>
                     </Field>
                 </div>
             </div>

         </div>


             <h4 className="mt-4">Section : 2</h4>
             <p>The Basic NIT Tender Information for Section 2</p>
            <div className="row border-bottom pb-3">
             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Procurement Category<span className="text-danger">*</span></label>
                     <Field name="procurement_category" component="select" className="redux-form-input form-control form-control-sm">
                         <option value="">Select Procurement Category</option>
                         <option value="Goods">Goods</option>
                         <option value="Works">Works</option>
                         <option value="Services">Services</option>
                     </Field>
                 </div>
             </div>

             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Time Allowed<span className="text-danger">*</span></label>
                     <Field name="time_allowed" component="input" type="number" placeholder="Number of Days" className="redux-form-input form-control form-control-sm" />
                 </div>
             </div>

             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Bid Submission Dates:<span className="text-danger">*</span></label>
                     <Field
                         name="bid_publish_date" type="date" component="input" className="redux-form-input form-control form-control-sm"
                         //   render={({ input }) => (
                         //     <DatePicker
                         //       selected={input.value}
                         //       onChange={(date) => input.onChange(date)}
                         //       showTimeSelect
                         //       timeFormat="HH:mm"
                         //       timeIntervals={15}
                         //       dateFormat="yyyy-MM-dd HH:mm"
                         //       className="form-control form-control-sm"
                         //     />
                         //   )}
                     />
                 </div>
             </div>

             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">

                     <label className="text-label">No. of Stages</label>
                     <Field name="num_stages" component="select" className="form-control form-control-sm">
                         <option value="">Select Option</option>
                         <option value="single_stage">Single</option>
                         <option value="two_stage">Two</option>
                         <option value="three_stage">Three</option>
                     </Field>
                 </div>
             </div>


             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Bid Submission Closing Date & Time<span className="text-danger">*</span></label>
                     <Field
                         name="bid_submission_closing_date"
                         type="date" component="input" className="redux-form-input form-control form-control-sm"
                         //   render={({ input }) => (
                         //     <DatetimePicker
                         //       onChange={(value) => input.onChange(value)}
                         //       value={input.value}
                         //     />
                         //   )}
                     />
                 </div>
             </div>


             {/* Pre-bid Meeting Selection */}
             <div className="col-lg-6 mb-2">
             <div className="form-group">
                 <label className="text-label">Pre-bid Meeting</label>
                 {/* <div className="form-check">
                                      <Field name="pre_bid_meeting" className="form-check-input" component="input" type="radio" value="yes" onChange={handlePreBidMeetingChange} />

                          <label className="form-check-label">
                          {' '} Yes                          </label>
                        </div>
                        <div className="form-check">
                                      <Field name="pre_bid_meeting" className="form-check-input" component="input" type="radio" value="no" onChange={handlePreBidMeetingChange} />

                          <label className="form-check-label">
                            No
                          </label>
                        </div> */}


                 <div className="form-group mb-0">
                     <div className="form-check">
                         <Field name="pre_bid_meeting" className="form-check-input" component="input" type="radio" value="yes" onChange={handlePreBidMeetingChange} />
                         <label className="form-check-label">
                             {' '} Yes
                         </label>
                     </div>
                      <div className="form-check">
                          <Field name="pre_bid_meeting" className="form-check-input" component="input" type="radio" value="no" onChange={handlePreBidMeetingChange} />
                          <label className="form-check-label">
                              {' '} No
                          </label>
                      </div>

                 </div>
             </div>
             </div>
             {showPreBidMeetingLocation && (

                 <div className="col-lg-6 mb-2">
                     <div className="form-group mb-3">
                         <label className="text-label">Date & Time of Pre-bid Meeting<span className="text-danger">*</span></label>
                         <Field
                             name="bid_opning_datetime"
                             type="date" component="input" className="redux-form-input form-control form-control-sm"
                             //   render={({ input }) => (
                             //     <DatetimePicker
                             //       onChange={(value) => input.onChange(value)}
                             //       value={input.value}
                             //     />
                             //   )}
                         />
                     </div>
                 </div>
             )}

             {/* Pre-bid Meeting Location */}
             {showPreBidMeetingLocation && (
                 <div className="col-lg-6 mb-2">
                     <div className="form-group mb-3">
                         <label className="text-label">Pre-bid Meeting Location</label>
                         <Field
                             name="pre_bid_meeting_location"
                             component="input"
                             type="text"
                             className="redux-form-input form-control form-control-sm"
                         />
                     </div>
                 </div>
             )}


             {/* Pre-bid Meeting Venue */}
             {showPreBidMeetingVenue && (
                 <div className="col-lg-6 mb-2">
                     <div className="form-group mb-3">

                         <label className="text-label">Pre-bid Meeting Venue</label>
                         <Field
                             name="pre_bid_meeting_venue"
                             component="input"
                             type="text"
                             className="form-control form-control-sm"
                             placeholder="Enter venue"
                         />
                     </div>
                 </div>
             )}

             {/* Tender Notice Type */}
             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">

                     <label className="text-label">Tender Notice Type</label>
                     <Field name="notice_type" component="select" className="form-control form-control-sm">
                         <option value="">Select Option</option>
                         <option value="standard">Standard Notice type</option>
                         <option value="custom">Notice type</option>
                     </Field>
                 </div>
             </div>

             <div className="col-lg-6 mb-2">
                 <div className="form-group">
                     <label className="text-label">Receipt of Queries through</label>

                     <div className="row">
                         <div className="col-lg-6">
                             <label>
                                 Interface
                             </label>
                             <Field name="query_receipt" component="input" type="text" value="interface" className="form-control form-control-sm" />
                         </div>
                         <div className="col-lg-6">

                             <label>
                                 Email
                             </label>
                             <Field name="query_receipt" component="input" type="text" value="email" className="form-control form-control-sm" />
                         </div>
                     </div>
                 </div>
             </div>


             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Bid Opening Dates & TIme<span className="text-danger">*</span></label>
                     <Field
                         name="bid_opning_datetime"
                         type="date" component="input" className="redux-form-input form-control form-control-sm"
                         //   render={({ input }) => (
                         //     <DatetimePicker
                         //       onChange={(value) => input.onChange(value)}
                         //       value={input.value}
                         //     />
                         //   )}
                     />
                 </div>
             </div>

             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Cover Selection for Stage</label>
                     <Field name="cover_selection" component="select" className="form-control form-control-sm">
                         <option value="">Select Option</option>
                         <option value="single_bid">Single Bid System</option>
                         <option value="two_bid">Two Bid System</option>
                         <option value="three_bid">Three Bid System</option>
                     </Field>
                 </div>
             </div>


             <div className="col-lg-6 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Bid Validity Period</label>
                     <Field name="bid_validity_period" component="select" className="form-control form-control-sm">
                         <option value="">Select Bid Validity Period</option>
                         <option value="30">30 Days for Single Stage After Opening of technical Bid</option>
                         <option value="75">75 Days for 2/3 Stage After Opening of technical Bid</option>
                     </Field>

                 </div>
             </div>

            </div>
             <h4 className="mt-4">Cover Selection</h4>
             <p>The Basic NIT Tender Information for Cover Selection</p>
             <div className="row">
            <div className="col-12 mb-3">
                <div className="form-check-inline">
                                                <Field name="cover_selection" className="form-check-input" component="input" type="radio" value="single_stage_bid" onChange={handleCoverSelectionChange} />

                                    <label className="form-check-label">
                                    {' '} Single Stage bid                          </label>
                                    </div>
                                    <div className="form-check-inline">
                                                <Field name="cover_selection" className="form-check-input" component="input" type="radio" value="two_stage_bid" onChange={handleCoverSelectionChange} />

                                    <label className="form-check-label">
                                    Two Stage Bid
                                    </label>
                                    </div>
                                    <div className="form-check-inline">
                                                <Field name="cover_selection" className="form-check-input" component="input" type="radio" value="three_stage_bid" onChange={handleCoverSelectionChange} />

                                    <label className="form-check-label">
                                    Three Stage Bid
                                    </label>
                                    </div>

            </div>
            {showCoverSelectionVenue=='two_stage_bid'?<div className="col-12 mb-3">
                <div className="form-check-inline">
                    <Field name="two_stage_bid" className="form-check-input" component="input" type="radio" value="Technical cum Eligibility Bid"  />
                                    <label className="form-check-label">
                                    {' '} Technical cum Eligibility Bid                       
                                       </label>
                                    </div>
                                    <div className="form-check-inline">
                                    <Field name="two_stage_bid" className="form-check-input" component="input" type="radio" value="Financial Bid"  />
                                    <label className="form-check-label">
                                    Financial Bid
                                    </label>
                </div>
            </div>:''}
            {showCoverSelectionVenue=='three_stage_bid'?<div className="col-12 mb-3">
                <div className="form-check-inline">
                                                <Field name="three_stage_bid" className="form-check-input" component="input" type="radio" value="Pre-Qualification Stage" />

                                    <label className="form-check-label">
                                    {' '} Pre-Qualification Stage                          </label>
                                    </div>
                                    <div className="form-check-inline">
                                                <Field name="three_stage_bid" className="form-check-input" component="input" type="radio" value="Technical cum Eligibility Bid" />

                                    <label className="form-check-label">
                                    Technical cum Eligibility Bid
                                    </label>
                                    </div>
                                    <div className="form-check-inline">
                                                <Field name="three_stage_bid" className="form-check-input" component="input" type="radio" value="Financial Bid"  />

                                    <label className="form-check-label">
                                    Financial Bid
                                    </label>
                                    </div>

            </div>:''}
             </div>
             <h4>Publicity of Tender</h4>
             <p>The Basic NIT Tender Information for Publicity of Tender
</p>
<table className="table ">
      <thead>
        <tr>
            <th></th>
          <th>Number of Days(Min)</th>
          <th>ECV-Estimate Tender Cost Value</th>
        </tr>
      </thead>
      <tbody>

          <tr>
            <td>Standard Notice Tender</td>
            <td>
                     <Field name="standard_notice_tender_duration_in_days" component="select" className="form-control form-control-sm redux-form-input">
                     <option value="">Select Option</option>
                     {standardNoticeTenderPeriods.map((item, index) => (
                         <option value={item.duration}>{item.duration}</option>

                         ))}
                         </Field>
                 </td>
                 <td>
                 <Field name="standard_notice_tender_cost_value" component="input" type="text" className="form-control form-control-sm redux-form-input"/>

                 </td>
          </tr>
          <tr>
            <td>Short Notice Tender</td>
            <td>
                     <Field name="short_notice_tender_duration_in_days" component="select" className="form-control form-control-sm redux-form-input">
                     <option value="">Select Option</option>
                     <option value="3 Days">3 Days</option>

                     {/* {standardNoticeTenderPeriods.map((item, index) => (
                         <option value={item.duration}>{item.duration}</option>

                         ))} */}
                         </Field>
                 </td>
                 <td>
                 <Field name="short_notice_tender_cost_value" component="input" type="text" className="form-control form-control-sm redux-form-input"/>

                 </td>
          </tr>
      </tbody>
    </table>
    <div className="clearfix"></div>
        {DemoComponent()}
        <div className="col-lg-6 mb-2">
                     <div className="form-group mb-3">
                         <label className="text-label">Work Location</label>
                         <Field
                             name="work_location"
                             component="textarea"
                             type="text"
                             className="redux-form-input form-control"
                             rows={'6'}
                         />
                     </div>
                 </div>

        
<h5>EMD DETAILS
</h5>

<div className="col-lg-12 mb-2">
                 <div className="form-group mb-3">
                     <label className="text-label">Type of Contract</label>
                     <div className="form-group mb-0">
                     <div className="form-check-inline">
                         <Field name="type_of_contract" className="form-check-input" component="input" type="radio" value="EPC" />
                         <label className="form-check-label">
                             {' '} EPC Contract
                         </label>
                     </div>
                      <div className="form-check-inline">
                          <Field name="type_of_contract" className="form-check-input" component="input" type="radio" value="Non-EPC"  />
                          <label className="form-check-label">
                              {' '} Non-EPC Contract
                          </label>
                      </div>

                 </div>
                 </div>
             </div>


      </section>
      <hr></hr>
      <div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-light sw-btn-prev me-1" type="reset">Reset</button>
										<button className="btn btn-primary sw-btn-next ms-1"  type="submit">Submit</button>
									</div>

      </form>
   );
};

// export default StepOne;
export default reduxForm({
   form: 'StepOne', // a unique identifier for this form
 })(StepOne);
