import React, { useState } from "react";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from 'redux-form';

const StepTwo = () => {
    const dispatch = useDispatch();
    const performanceGuarantee = useSelector(state => state.performanceGuarantee);
    const securityDeposit = useSelector(state => state.securityDeposit);
    const selectedModesOfPayment = useSelector(state => state.selectedModesOfPayment || []); // Provide a default empty array
    const [applicableClause, setApplicableClause] = useState('');
    const [clause5Days, setClause5Days] = useState('');
    const [milestones, setMilestones] = useState([
        { id: 1, description: '', daysAllowed: '', withholdingAmount: '' }
    ]);
    const [partA, setPartA] = useState({ withoutHindrance: '', timePeriod: '' });
    const [partB, setPartB] = useState({ withEncumbrances: '', timePeriod: '' });
    const [partC, setPartC] = useState({ dependentOnOtherAgencies: '', timePeriod: '' });
    const [testingEquipment, setTestingEquipment] = useState(['']);
    const names = [
        { id: 1, name: 'John Doe', address: '123 Main St, City A' },
        { id: 2, name: 'Jane Smith', address: '456 Elm St, City B' },
        { id: 3, name: 'Alice Johnson', address: '789 Oak St, City C' },
        // Add more names as needed
      ];
      
   //  for input collection form
    const handleInputChange = (index, value) => {
        const updatedEquipment = [...testingEquipment];
        updatedEquipment[index] = value;
        setTestingEquipment(updatedEquipment);
    };
  
    const handleAddField = () => {
        setTestingEquipment([...testingEquipment, '']);
    };
    const handleRemoveField = (index) => {
     const updatedEquipment = [...testingEquipment];
     updatedEquipment.splice(index, 1);
     setTestingEquipment(updatedEquipment);
 };

 // for 38
 const handleNameChange = (event) => {
    const selectedName = event.target.value;
    // Fetch the corresponding address based on the selected name
    // const selectedAddress = addresses.find((address) => address.name === selectedName)?.address || '';
    // selectNameAndAddress(selectedName, selectedAddress);
  };

 // for Clause 10 CC Table 1
 const [rows, setRows] = useState([
   { component: 'Cement', percentage: '' },
   { component: 'Labour', percentage: '' },
   { component: 'Civil Component of other Construction Materials', percentage: '' },
   { component: 'E&M (Electrical and Mechanical) component of Construction Materials', percentage: '' },
   { component: 'POL (Diesel) component', percentage: '' },
   { component: 'Reinforcement steel bars/TMT bars/structural steels (including strands and cables) component', percentage: '' },
   { component: 'Bitumen component', percentage: '' },
 ]);
 const [constructionRows, setConstructionRows] = useState([
   { component: 'Cement', percentage: '' },
   { component: 'Labour', percentage: '' },
   { component: 'Civil Component of other Construction Materials', percentage: '' },
   { component: 'E&M (Electrical and Mechanical) component of Construction Materials', percentage: '' },
   { component: 'POL (Diesel) component', percentage: '' },
   { component: 'Reinforcement steel bars/TMT bars/structural steels (including strands and cables) component', percentage: '' },
   { component: 'Bitumen component', percentage: '' },
 ]);
 
 const [maintenanceRows, setMaintenanceRows] = useState([
   { component: 'Labour', percentage: '' },
   { component: 'Civil Component of other Construction Materials', percentage: '' },
   { component: 'E&M (Electrical and Mechanical) component of Construction Materials', percentage: '' },
   { component: 'Bitumen component (For Road work component)', percentage: '' },
 ]);
 
 const handlePercentageChange = (index, value, period) => {
   if (period === 'construction') {
       const updatedRows = [...constructionRows];
       updatedRows[index].percentage = value;
       setConstructionRows(updatedRows);
   } else if (period === 'maintenance') {
       const updatedRows = [...maintenanceRows];
       updatedRows[index].percentage = value;
       setMaintenanceRows(updatedRows);
   }
 };
 
 
 
 // for table collection form
    const handleMilestoneChange = (index, field, value) => {
        const updatedMilestones = [...milestones];
        updatedMilestones[index][field] = value;
        setMilestones(updatedMilestones);
    };
  
    const handleAddMilestone = () => {
        setMilestones([...milestones, { id: milestones.length + 1, description: '', daysAllowed: '', withholdingAmount: '' }]);
    };
  
    const handleRemoveMilestone = (index) => {
        const updatedMilestones = [...milestones];
        updatedMilestones.splice(index, 1);
        setMilestones(updatedMilestones);
    };
  
  
    const handleApplicableClauseChange = (e) => {
        setApplicableClause(e.target.value);
    };
 
    const handlePerformanceGuaranteeChange = (e) => {
    //   dispatch(updatePerformanceGuarantee(e.target.value));
    };
  
    const handleSecurityDepositChange = (e) => {
    //   dispatch(updateSecurityDeposit(e.target.value));
    };
 
 const handleModeOfPaymentChange = (e) => {
   const selectedMode = e.target.value;
   let updatedModesOfPayment = [...selectedModesOfPayment];
 
   if (e.target.checked) {
     updatedModesOfPayment.push(selectedMode);
   } else {
     updatedModesOfPayment = updatedModesOfPayment.filter(mode => mode !== selectedMode);
   }
 //   dispatch(updateModeOfPayment(updatedModesOfPayment));
 };
 
 const ScheduleInputField = ({ label, name }) => (
    <div className="col-lg-6 mb-2">
      <div className="form-group mb-3">
        <label className="text-label">{label}</label>
        <Field
          name={name}
          component="input"
          type="text"
          placeholder={`Enter ${label}`}
          className="redux-form-input form-control form-control-sm"
        />
      </div>
    </div>
  );
  const handleQuantityChange = (index, e) => {
   const newQuantity = parseInt(e.target.value);
   // updateQuantity(index, newQuantity);
 };
 
 

    const scheduleA=() =>{
        return(<>
            <div className="col-lg-6 mb-2">
                <div className="form-group mb-3">
                    {/* {quantities.map((quantity, index) => ( */}
                    <label>Quantity </label>
                    <Field name="qty" component="input" className="redux-form-input form-control form-control-sm"
                           type="number"
                           value="1"
                        // onChange={(e) => handleQuantityChange(index, e)}
                    />
                </div>
                {/* ))} */}
            </div>

        </>);
    }
    const scheduleE=() =>{
        return(<>
            <div className="col-lg-6 mb-2">
                <div className="form-group mb-3">
                    <label>Name of work </label>
                    <Field name="name_of_work" component="input" className="redux-form-input form-control form-control-sm"
                           type="text"
                           value=""
                    />
                </div>
            </div>
            <div className="col-lg-6 mb-2">
                <div className="form-group mb-3">
                    <label>Estimated cost of work (Civil or Electrical or Both) </label>
                    <Field name="estimated_cost_of_work" component="input" className="redux-form-input form-control form-control-sm"
                           type="text"
                           value=""
                    />
                </div>
            </div>
            <div className="col-lg-4 mb-2">
                <div className="form-group mb-3">
                    <label>Earnest money</label>
                    <div className="input-group mb-3">

                        <Field type="text" name="earnest_money" component="input" className="redux-form-input form-control form-control-sm"  />
                        <button className="btn btn-primary btn-sm" type="button">
                            %
                        </button>

                    </div>
                </div>
            </div>
            <div className="col-lg-4 mb-2">
                <div className="form-group mb-3">
                    <label>Performance Guarantee</label>
                    <div className="input-group mb-3">
                        <Field type="text" name="performance_guarntee" component="input" className="redux-form-input form-control form-control-sm"  />
                        <button className="btn btn-primary btn-sm" type="button">
                            %
                        </button>

                    </div>
                </div>
            </div>
            <div className="col-lg-4 mb-2">
                <div className="form-group mb-3">
                    <label>Security Deposit</label>
                    <div className="input-group mb-3">
                        <Field type="text" name="security_deposit" component="input" className="redux-form-input form-control form-control-sm"  />
                        <button className="btn btn-primary btn-sm" type="button">
                            %
                        </button>

                    </div>
                </div>
            </div>

        </>);
    }
    const GeneralRules = () => {
        return (
            <div className="general-rules">
                <h3>GENERAL RULES & DIRECTIONS</h3>
                <div className="section">
                    <h4>Officer inviting tender</h4>
                    <p>NAME OF OFFICER</p>
                </div>
                <div className="section">
                    <h4>Maximum percentage for quantity of items of work</h4>
                    {/* <p>
                  Maximum percentage for quantity of items of work to be executed beyond which rates are to be determined in accordance with Clauses 12.2 & 12.3.
              </p> */}
                </div>
            </div>
        );
    };
    const scheduleF=() =>{
        return(<>
            {GeneralRules()}
            <div className="col-lg-3 mb-2">
                <div className="form-group mb-3">
                    <label>Engineer-in-Charge</label>
                    <Field name="engineer_in_charge" component="input" className="redux-form-input form-control form-control-sm"
                           type="text"
                           value=""
                    />
                </div>
            </div>

            <div className="col-lg-3 mb-2">
                <div className="form-group mb-3">
                    <label>Accepting Authority</label>
                    <Field name="accepting_authority" component="input" className="redux-form-input form-control form-control-sm"
                           type="text"
                           value=""
                    />
                </div>
            </div>

            <div className="col-lg-6 mb-2">
                <div className="form-group mb-3">
                    <label>Percentage on cost of materials and labour to cover all overheads and profits</label>
                    <div className="input-group mb-3">
                        <Field type="text" name="per_on_cost_material" value={'15'} component="input" className="redux-form-input form-control form-control-sm"  />
                        <button className="btn btn-primary btn-sm" type="button">
                            %
                        </button>

                    </div>
                </div>
            </div>
            <div className="col-lg-3 mb-2">
                <div className="form-group mb-3">
                    <label>Standard Schedule of Rates</label>
                    <Field name="standard_schedule_of_rates" component="input" className="redux-form-input form-control form-control-sm"
                           type="text"
                           value=""
                    />
                </div>
            </div>

            <div className="col-lg-3 mb-2">
                <div className="form-group mb-3">
                    <label>Department</label>
                    <Field name="standard_schedule_of_rates" component="input" className="redux-form-input form-control form-control-sm"
                           type="text"
                           value=""
                    />
                </div>
            </div>
            <div className="col-lg-6 mb-2">
                <div className="form-group mb-3">
                    <label>Standard CPWD contract Form GCC 2020, CPWD Form 7/ 8 as modified & corrected upto</label>
                    <Field type="text" name="standard_cpwd_contact_form" value={'15'} component="input" className="redux-form-input form-control form-control-sm"  />

                </div>
            </div>
            <div className="section">
                <h4>Clause 1</h4>

            </div>
            <div className="col-lg-6 mb-2">

                <div className="form-group mb-3">
                    <label>
                        Time allowed for submission of Performance Guarantee, program chart,
                        and applicable labor licenses, registration with EPFO, ESIC, and BOCW
                        welfare board or proof of applying thereof from the date of issue
                        of the letter of acceptance
                    </label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control form-control-sm redux-form-input"
                            component="input"
                            placeholder="Enter number of days"
                            name="performanceGuaranteeSubmissionDays"
                            // Add necessary event handlers and state to manage the input value
                        />
                        <span className="input-group-text">Days</span>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 mb-2">

                <div className="form-group mb-3">
                    <label>
                        Maximum allowable extension with late fee @ 0.1% per day of Performance Guarantee
                        amount beyond the period provided in
                    </label>
                    <small className="form-text text-danger">Up to 15 days</small>

                    <div className="input-group">
                        <Field
                            type="number"
                            component="input"
                            className="form-control form-control-sm redux-form-input"
                            placeholder="Enter number of days"
                            name="maximumExtensionDays"
                            // Add necessary event handlers and state to manage the input value
                        />
                        <span className="input-group-text">Days</span>
                    </div>
                </div>
            </div>
            <div className="section">
                <h4>Clause 2</h4>


                <div className="col-lg-6 mb-2">
                    <div className="form-group mb-3">
                        <label>Authority for fixing compensation</label>
                        <Field name="authority_for_fixing_compensation" component="input" className="redux-form-input form-control form-control-sm"
                               type="text"
                               value=""
                        />
                    </div>
                </div>
                <h4>Clause 2A</h4>
                <div className="form-group mb-3">
                    <label>Applicable Clause 2/Clause 2A</label>
                    <div className="form-check">
                        <Field
                            component="input"
                            className="redux-form-input form-check-input"
                            type="radio"
                            name="applicableClause"
                            id="clause2"
                            value="yes"
                            checked={applicableClause === 'yes'}
                            onChange={handleApplicableClauseChange}
                        />
                        <label className="form-check-label" htmlFor="clause2">
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <Field
                            component="input"
                            className="redux-form-input form-check-input"
                            type="radio"
                            name="applicableClause"
                            id="clause2A"
                            value="no"
                            checked={applicableClause === 'no'}
                            onChange={handleApplicableClauseChange}
                        />
                        <label className="form-check-label" htmlFor="clause2A">
                            No
                        </label>
                    </div>
                </div>

            </div>
            <div className="section">
                <h4>Clause 5</h4>
            </div>
            {Clause5Form()}


        </>);
    }
    const Clause5Form = () => {
        return (
            <>
                <div className="section mb-3">
                    <h5 className="text-muted">TABLE 1 CLAUSE 5</h5>

                </div>
                <div className="col-6">

                    <div className="form-group mb-3">
                        <label> Number of days from the date of issue of letter of acceptance for reckoning date of start</label>
                        <div className="input-group">
                            <Field
                                component="input"
                                type="number"
                                className="form-control form-control-sm redux-form-input"
                                value={clause5Days}
                                onChange={(e) => setClause5Days(e.target.value)}
                            />
                            <span className="input-group-text">Days</span>
                        </div>

                    </div>
                </div>
                <div className="col-6">

                    <div className="form-group mb-3">
                        <label>Time allowed for execution of work.</label>
                        <Field
                            component="input"
                            type="number"
                            className="form-control form-control-sm redux-form-input"
                            name="time_allowed_execution_of_work"
                        />
                    </div>
                </div>
                <div className="col-12 table-responsive mb-3">
                    <table id="example" className="table  nowrap" style={{width:'100%'}}>
                        <thead>
                        <tr>
                            <th><h6 className="text-muted">SI No</h6></th>
                            <th><h6 className="text-muted">Description of Milestone (Physical)</h6></th>
                            <th><h6 className="text-muted">Time Allowed in days (from date of start)</h6></th>
                            <th><h6 className="text-muted">Amount to be with-held in case of non achievement of milestone</h6></th>
                            <th><h6 className="text-muted">Action</h6></th>
                        </tr>
                        </thead>
                        <tbody>
                        {milestones.map((milestone, index) => (
                            <tr key={milestone.id}>
                                <td>{index + 1}</td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={milestone.description}
                                        onChange={(e) => handleMilestoneChange(index, 'description', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        value={milestone.daysAllowed}
                                        onChange={(e) => handleMilestoneChange(index, 'daysAllowed', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={milestone.withholdingAmount}
                                        onChange={(e) => handleMilestoneChange(index, 'withholdingAmount', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <button className="btn btn-danger btn-sm" type="button" onClick={() => handleRemoveMilestone(index)}><i className="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <button className="btn btn-primary btn-sm" type="button" onClick={handleAddMilestone}>Add Milestone</button>

                </div>
                <div className="section mb-3 mt-2">
                    <h5 className="text-muted mb-2">TABLE 2 CLAUSE 5</h5>
                    <h6>Authority to decide:	</h6>
                </div>
                <div className="col-4 mb-2">

                    <div className="form-group mb-3">
                        <label>Extension of time</label>
                        <div className="input-group">
                            <Field
                                component="input"
                                type="number"
                                className="form-control form-control-sm redux-form-input"
                                value={clause5Days}
                                onChange={(e) => setClause5Days(e.target.value)}
                            />
                            <span className="input-group-text">Days</span>
                        </div>

                    </div>
                </div>
                <div className="col-4 mb-2">

                    <div className="form-group mb-3">
                        <label> Rescheduling of mile stones</label>
                        <div className="input-group">
                            <Field
                                component="input"
                                type="number"
                                className="form-control form-control-sm redux-form-input"
                                value={clause5Days}
                                onChange={(e) => setClause5Days(e.target.value)}
                            />
                            <span className="input-group-text">Days</span>
                        </div>

                    </div>
                </div>
                <div className="col-4 mb-2">

                    <div className="form-group mb-3">
                        <label>Shifting of date of start in case of delay in handing over of site</label>
                        <div className="input-group">
                            <Field
                                component="input"
                                type="number"
                                className="form-control form-control-sm redux-form-input"
                                value={clause5Days}
                                onChange={(e) => setClause5Days(e.target.value)}
                            />
                            <span className="input-group-text">Days</span>
                        </div>

                    </div>
                </div>
                {ScheduleOfHandingOver()}

                <div className="section">
                    <h5>Clause 6</h5>
                    <label>Mode of measurement</label>

                    {/* <h6 className="text-muted">Computerised Measure Book (CMB) / Electronic Measurement Book (EMB)</h6> */}
                    <div className="form-group mb-3">
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="mode_of_measurement"
                                id="mode_of_measurement"
                                value="CMB"
                                checked={applicableClause === 'CMB'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="clause2">
                                Computerised Measure Book (CMB)
                            </label>
                        </div>
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="mode_of_measurement"
                                id="mode_of_measurement"
                                value="EMB"
                                checked={applicableClause === 'EMB'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="clause2A">
                                Electronic Measurement Book (EMB)
                            </label>
                        </div>
                    </div>

                </div>
                <div className="section">
                    <h5>Clause 7</h5>
                </div>
                <div className="col-6 mb-3">
                    <div className="form-group mb-3">
                        <label>
                            Gross work to be done together with net payment /adjustment of advances for material collected, if any, since the last such payment for being eligible to interim payment
                        </label>
                        <div className="input-group">
                            <Field
                                type="text"
                                className="form-control form-control-sm redux-form-input"
                                component="input"
                                placeholder="Net payment /adjustment of advances for material collected"
                                name="net_payment_adjustment_of_advances_for_material"
                                // Add necessary event handlers and state to manage the input value
                            />
                            <span className="input-group-text">₹</span>
                        </div>
                    </div>

                </div>
                <div className="section mb-3">
                    <h6>Clause 7A</h6>
                </div>
                <div className="col-12 mb-2">
                    <label>Whether clause 7A shall be applicable</label>

                    <div className="form-group mb-3">
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="clause7_applicable"
                                value="yes"
                                checked={applicableClause === 'yes'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="clause2">
                                Yes
                            </label>
                        </div>
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="clause7_applicable"
                                id=""
                                value="no"
                                checked={applicableClause === 'no'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="clause2A">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div className="section mb-3">
                    <h6>Clause 8A</h6>
                </div>
                <div className="col-6 mb-3">
                    <div className="form-group mb-3">
                        <label>
                            Authority to decide compensation on account if contractor fails to submit completion plans
                        </label>
                        <Field
                            type="text"
                            className="form-control form-control-sm redux-form-input"
                            component="input"
                            placeholder="Authority to decide compensation on account if contractor fails to submit completion plans"
                            name="authority_to_decide_compention_on_account_if_contractor_fails_to_submit_completion_plans"
                            // Add necessary event handlers and state to manage the input value
                        />
                    </div>
                </div>

                {Clause10AForm()}
                <div className="col-12 mt-5">
                    <h6>Clause10B(ii)</h6>
                    <label>Whether Clause10B(ii) shall be applicable</label>
                    <div className="form-group mb-3">
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="clause_ten_applicable"
                                id="yes"
                                value="yes"
                                checked={applicableClause === 'yes'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="yes">
                                Yes
                            </label>
                        </div>
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="clause_ten_applicable"
                                id="clause_ten_applicable"
                                value="no"
                                checked={applicableClause === 'no'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="clause_ten_applicable">
                                No
                            </label>
                        </div>
                    </div>

                </div>
                <div className="col-12 mb-3">
                    <h6>Clause 10 C</h6>
                    <div className="form-group mb-3">
                        <label>Component of labour expressed as percent of value of work</label>
                        <Field component="input" type="text" name="component_labour_expressed_value_of_work" className="form-control form-control-sm redux-form-input" value="" />

                    </div>
                </div>
                <div className="col-12 mb-3">
                    <h6>Clause 10 CC</h6>
                    <label>Applicable/ Not Applicable ( One Option to be kept by NIT approving authority)</label>
                    <div className="form-group mb-3">
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="clause_ten_cc_applicable"
                                id="yes"
                                value="yes"
                                checked={applicableClause === 'yes'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="yes">
                                Yes
                            </label>
                        </div>
                        <div className="form-check-inline">
                            <Field
                                component="input"
                                className="redux-form-input form-check-input"
                                type="radio"
                                name="clause_ten_cc_applicable"
                                id="clause_ten_cc_applicable"
                                value="no"
                                checked={applicableClause === 'no'}
                                onChange={handleApplicableClauseChange}
                            />
                            <label className="form-check-label" htmlFor="clause_ten_applicable">
                                No
                            </label>
                        </div>
                    </div>

                </div>
                <div className="section">
                    <h5>Table 1 Clause 10 CC</h5>
                    <label>A. For construction period</label>
                </div>
                {Clause10CCTable()}
                <label>B. For maintenance period
</label>
{Clause10CCTableB()}

<div className="section">
  <h5>Clause 11</h5>
  {Clause11()}
  
</div>
<div className="section">
  <h5>Clause 12</h5>
  {Clause12()}

</div>
<div className="section">
  <h5>Clause 12.2 & 12.3</h5>
  {ClauseInput({label:'Deviation Limit beyond which clauses12.2 & 12.3 shall apply for building work',name:'deviation_limit_apply_for_building_work'})}

</div>
<div className="section">
  <h5>Clause 12.4</h5>
  {ClauseInput({label:'(i) Deviation Limit beyond which clauses 12.2 & 12.3 shall apply for foundation work (except items mentioned in earth work subhead in DSR and related items)',name:'deviation_limit_for_foundation_work'})}
  {ClauseInput({label:'(ii) Deviation Limit for items mentioned in earth work subhead of DSR and related items',name:'deviation_limit_for_item_mentioned_in_earth_work'})}

</div>

<div className="section">
  <h5>Clause 16</h5>
  {ClauseInput({label:'Competent Authority for deciding reduced rates',name:'competent_authority_for_deciding_reduced_rate'})}

</div>

<div className="section">
  <h5>Clause 18</h5>
  {Clause18()}

</div>
<div className="section">
  <h5>Clause 19C</h5>
  {ClauseInput({label:'authority to decide penalty for each default',name:'authority_to_decide_penalty_for_19C'})}

</div>
<div className="section">
  <h5>Clause 19D</h5>
  {ClauseInput({label:'Authority to decide penalty for each default',name:'authority_to_decide_penalty_for_19D'})}

</div>
<div className="section">
  <h5>Clause 19G</h5>
  {ClauseInput({label:'Authority to decide penalty for each default',name:'authority_to_decide_penalty_for_19G'})}

</div>
<div className="section">
  <h5>Clause 19K</h5>
  {ClauseInput({label:'Authority to decide penalty for each default',name:'authority_to_decide_penalty_for_19cK'})}

</div>
<div className="section">
  <h5>Clause 25</h5>
</div>
<div className="col-4 mb-3">
{ClauseInput({label:'(i) Conciliator',name:'conciliator'})}

</div>
<div className="col-4 mb-3">
{ClauseInput({label:'(ii) Arbitrator Appointing Authority',name:'arbitrator_appointing_authority'})}

</div>
<div className="col-4 mb-3">
{ClauseInput({label:'(iii) Place of Arbitration',name:'place_of_arbitation'})}

</div>
<div className="section">
  <h5>Clause 32</h5>
  {Clause32Table()}
</div>
<div className="section">
  <h5>Clause 38</h5>
</div>
<div className="col-6 mb-3">
  {ClauseInputGrouup({label:'(i) (a). Schedule/statement for determining theoretical quantity of cement & bitumen on the basis of Delhi Schedule of Rates .................. printed by C.P.W.D',name:'statement_for_determining_theoretical_quantity_of_cement_basis_of_delhi_rates' ,type:"text",icon:'%'})}
  </div>
  <div className="col-6 mb-3">
  {ClauseInput({label:'(ii) Variations permissible on theoretical quantities',name:'variations_permissible_theoretical_quantities'})}
</div>
<div className="section">
  <h6>Provision of Independent External Monitors</h6>
</div>
<div className="col-4 mb-3">
  {ClauseInputGrouup({label:'(i) Threshold value (Estimated cost put to tender) at and above which Integrity Pact would be applicable',name:'statement_for_determining_theoretical_quantity_of_cement_basis_of_delhi_rates' ,type:"text",icon:'₹'})}
  </div>
  <div className="col-4 mb-3">

    <div className="form-group ">
    <label className="text-label ">Select Name</label>

       <select className="form-control-sm form-control redux-form-input mt-4" value={''} onChange={handleNameChange}>
        <option value="">Select Name</option>
        {names.map((name) => (
          <option key={name.id} value={name.name}>
            {name.name}
          </option>
        ))}
      </select>
      </div>
      </div>
      <div className="col-4 mb-3">
        <div className="form-group ">
<label>Address</label>
         <textarea className="form-control redux-form-input mt-2" value="" rows={'2'}  />
        </div>
     </div>
            </>
        );
    };
    const Clause32Table = () => {
        const [representatives, setRepresentatives] = useState([
            { qualification: '', designation: '', experience: '', recoveryRate: '' }
        ]);
    
        const handleAddRow = () => {
            setRepresentatives([...representatives, { qualification: '', designation: '', experience: '', recoveryRate: '' }]);
        };
    
        const handleRemoveRow = (index) => {
            const updatedRepresentatives = [...representatives];
            updatedRepresentatives.splice(index, 1);
            setRepresentatives(updatedRepresentatives);
        };
    
        const handleInputChange = (index, fieldName, value) => {
            const updatedRepresentatives = [...representatives];
            updatedRepresentatives[index][fieldName] = value;
            setRepresentatives(updatedRepresentatives);
        };
    
        return (
            <div>
                <h4>Requirement of Technical Representative(s) and recovery Rate</h4>
                <button className="btn btn-primary btn-sm mb-3" type="button" onClick={handleAddRow}>Add Row</button>
                <table className="table">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Minimum Qualification for technical representative</th>
                            <th>Designation</th>
                            <th>Min Experience</th>
                            <th>Rate at which recovery shall be made</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {representatives.map((representative, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td><Field component="input" name="" className="form-control form-control-sm redux-form-input" type="text" value={representative.qualification} onChange={(e) => handleInputChange(index, 'qualification', e.target.value)} /></td>
                                <td><Field component="input" name="" className="form-control form-control-sm redux-form-input" type="text" value={representative.designation} onChange={(e) => handleInputChange(index, 'designation', e.target.value)} /></td>
                                <td><Field component="input" name="" className="form-control form-control-sm redux-form-input" type="text" value={representative.experience} onChange={(e) => handleInputChange(index, 'experience', e.target.value)} /></td>
                                <td><Field component="input" name="" className="form-control form-control-sm redux-form-input" type="text" value={representative.recoveryRate} onChange={(e) => handleInputChange(index, 'recoveryRate', e.target.value)} /></td>
                                <td><button className="btn btn-danger btn-sm" type="button" onClick={() => handleRemoveRow(index)}><i className="fa fa-trash"></i></button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    
    
    const Clause18 = () => {
        const [machineryList, setMachineryList] = useState([""]);
    
        const handleAddMachinery = () => {
            setMachineryList([...machineryList, ""]);
        };
    
        const handleRemoveMachinery = (index) => {
            const updatedList = [...machineryList];
            updatedList.splice(index, 1);
            setMachineryList(updatedList);
        };
    
        const handleMachineryChange = (index, value) => {
            const updatedList = [...machineryList];
            updatedList[index] = value;
            setMachineryList(updatedList);
        };
    // console.log(machineryList)
        return (
            <div>
                <h4>List of mandatory machinery, tools & plants to be deployed by the contractor at site:</h4>
                <button className="btn btn-primary mb-3 btn-sm" type="button" onClick={handleAddMachinery}>Add Machinery</button>
                <table className="table">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Machinery/Tool/Plant</th>
                            <th>Qty</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {machineryList.map((machinery, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <Field
                                    component='input'
                                        type="text"
                                        name="machinery[]"
                                        className="redux-form-input form-control form-control-sm"
                                        value={machinery}
                                        onChange={(e) => handleMachineryChange(index, e.target.value)}
                                    />
                                </td>
                                <td>
                                    <Field
                                    component='input'
                                        type="text"
                                        name="qty[]"
                                        value=""
                                        className="redux-form-input form-control form-control-sm"

                                        // onChange={(e) => handleMachineryChange(index, e.target.value)}
                                    />
                                </td>
                                <td>
                                    <button className="btn btn-danger btn-sm" type="button" onClick={() => handleRemoveMachinery(index)}><i className="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    
    const ClauseInputGrouup = ({label,name,type,icon}) => {
        const [specifications, setSpecifications] = useState('');
      
        const handleSpecificationsChange = (event) => {
            setSpecifications(event.target.value);
        };
      
        return (
            <div className="form-group mb-3">
                <label>{label}</label>
                <div className="input-group mb-3">
                        <Field type={type} name={name} 
                    onChange={handleSpecificationsChange} value={specifications} component="input" className="redux-form-input form-control form-control-sm"  />
                        <button className="btn btn-primary btn-sm" type="button">
                        {icon}
                        </button>

                </div>
            </div>
        );
      };
    const ClauseInput = ({label,name}) => {
        const [specifications, setSpecifications] = useState('');
      
        const handleSpecificationsChange = (event) => {
            setSpecifications(event.target.value);
        };
      
        return (
            <div className="form-group mb-3">
                <label>{label}</label>
                <Field
                 name={name}
                 component='input'
                    className="form-control form-control-sm redux-form-input"
                    value={specifications}
                    onChange={handleSpecificationsChange}
                    rows="5"
                />
            </div>
        );
      };
      const Clause12 = () => {
        const [specifications, setSpecifications] = useState('');
      
        const handleSpecificationsChange = (event) => {
            setSpecifications(event.target.value);
        };
      
        return (
            <div className="form-group mb-3">
                <label>Authority to decide deviation upto 1.5 time of tendered amount .</label>
                <Field
                component="input"
                    className="form-control form-control-sm"
                    value={specifications}
                    onChange={handleSpecificationsChange}
                    rows="5"
                />
            </div>
        );
      };
      const Clause11 = () => {
        const [specifications, setSpecifications] = useState('');
      
        const handleSpecificationsChange = (event) => {
            setSpecifications(event.target.value);
        };
      
        return (
            <div className="form-group mb-3">
                <label>Specifications to be followed for execution of work:</label>
                <Field
                    component="input"
                    className="form-control form-control-sm"
                    value={specifications}
                    onChange={handleSpecificationsChange}
                    rows="5"
                />
            </div>
        );
      };
      const Clause10CCTableB = () => {
        return(<>
                  <table className="table">
                      <thead>
                          <tr>
                              <th>S No.</th>
                              <th>Relevant Component</th>
                              <th>Percentage of Total Value of Work</th>
                          </tr>
                      </thead>
                      <tbody>
                          {maintenanceRows.map((row, index) => (
                              <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{row.component}</td>
                                  <td>
                                      <input
                                          type="text"
                                          className="form-control form-control-sm"
                                          value={row.percentage}
                                          onChange={(e) => handlePercentageChange(index, e.target.value, 'maintenance')}
                                      />
                                  </td>
                              </tr>
                          ))}
                          <tr>
                              <td colSpan="2"><strong>TOTAL</strong></td>
                              <td><strong>100%</strong></td>
                          </tr>
                      </tbody>
                  </table>
      
      </>)
      }
    const Clause10CCTable = () => {

        return (
            <table className="table">
                <thead>
                <tr>
                    <th>S No.</th>
                    <th>Relevant Component</th>
                    <th>Percentage of Total Value of Work</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.component}</td>
                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={row.percentage}
                                onChange={(e) => handlePercentageChange(index, e.target.value)}
                            />
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan="2"><strong>TOTAL</strong></td>
                    <td><strong>100%</strong></td>
                </tr>
                </tbody>
            </table>
        );
    };

    const Clause10AForm = () => {

        return (
            <div className="section">
                <h5>Clause 10A:</h5>
                <label>List of testing equipment to be provided by the contractor at site lab</label>
                {testingEquipment.map((equipment, index) => (<>
                        <div className="row" key={index}>
                            <div className="col-2 mb-2">
                                <label>{index+1}</label>
                            </div>
                            <div className="col-8 mb-2">
                                <Field component="input" type="text" className="form-control form-control-sm redux-form-input" value="" onChange={(e) => handleInputChange(index, e.target.value)} placeholder={`Give name of instrument ${index + 1}`}/>
                            </div>
                            <div className="col-2 mb-2">
                                {index==0? <button className="btn btn-danger btn-sm" type="button" onClick={() => handleRemoveField(index)}><i className="fa fa-trash"></i></button>:''}
                            </div>
                        </div>
                    </>
                ))}
                <button className="btn btn-primary btn-sm" type="button" onClick={handleAddField}>Add Field</button>

            </div>
        );
    };



    const ScheduleOfHandingOver = () => {
        return (
            <div>
                <h5 className="text-muted">TABLE 3 CLAUSE 5 </h5>
                <h6> PROFORMA OF SCHEDULES - Clause 5 Schedule of handing over of site</h6>
                <table className="table">
                    <thead>
                    <tr>
                        <th><h5 className="text-muted">Part</h5></th>
                        <th><h5 className="text-muted">Portion of site</h5></th>
                        <th><h5 className="text-muted">Portion of site</h5></th>
                        <th><h5 className="text-muted">Time Period for handing over reckoned from date of issue of letter of intent.</h5></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Part A</td>
                        <td>
                            <label>Portion without any hindrance</label>
                        </td>
                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={partA.withoutHindrance}
                                onChange={(e) => setPartA({ ...partA, withoutHindrance: e.target.value })}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={partA.timePeriod}
                                onChange={(e) => setPartA({ ...partA, timePeriod: e.target.value })}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Part B</td>
                        <td>
                            <label>Portions with encumbrances</label>
                        </td>

                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={partB.withEncumbrances}
                                onChange={(e) => setPartB({ ...partB, withEncumbrances: e.target.value })}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={partB.timePeriod}
                                onChange={(e) => setPartB({ ...partB, timePeriod: e.target.value })}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Part C</td>
                        <td>
                            <label>Portions dependent on work of other agencies</label>
                        </td>

                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={partC.dependentOnOtherAgencies}
                                onChange={(e) => setPartC({ ...partC, dependentOnOtherAgencies: e.target.value })}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                value={partC.timePeriod}
                                onChange={(e) => setPartC({ ...partC, timePeriod: e.target.value })}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };


    
    const defaultAccordion = [
        {
            title: "SCHEDULE 'A'",
            text:scheduleA(),
            bg: "primary",

        },
        {
            title: "SCHEDULE ‘E’",
            text:scheduleE(),
            bg: "info",

        },
        {
            title: "SCHEDULE ‘F’",
            text:scheduleF(),
            bg: "success",

        },
        //  {
        //    title: "Clause 2 : Compensation for Delay",
        //    text:
        //      "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

        //    bg: "success",

        //  },

    ];

    return (
        <section>
            <Col xl="12">

                    {/* <Card.Header className="d-block">
              <Card.Title>Accordion Header Background</Card.Title>
              <Card.Text className="m-0 subtitle">
                Add <code>accordion-header-bg</code> class with{" "}
                <code>accordion</code>
              </Card.Text>
            </Card.Header> */}

                        <h4>Scheudule and Clause</h4>
                        <p>Here you have to fill up information for schedule and clause for NIT tender</p>
                        <form onSubmit="">

                            <Accordion className="accordion accordion-primary" defaultActiveKey="0">
                                {defaultAccordion.map((d, i) => (
                                    <Accordion.Item className="accordion-item" key={i} eventKey={`${i}`}>
                                        <Accordion.Header className="accordion-header rounded-lg">
                                            {d.title}
                                        </Accordion.Header>
                                        <Accordion.Collapse eventKey={`${i}`}>
                                            <div className="accordion-body">
                                                <div className="row">

                                                    {d.text}
                                                </div>
                                            </div>
                                            {/* {i === 0 ? <div className="accordion-body">{d.text}</div>






                     :
                      (i === 1 ? <div className="accordion-body">{d.text}</div> : null)
                     } */}

                                        </Accordion.Collapse>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                            <hr></hr>
      <div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-light sw-btn-prev me-1" type="reset">Reset</button>
										<button className="btn btn-primary sw-btn-next ms-1"  type="submit">Submit</button>
									</div>

                        </form>

            </Col>
            {/* <div className="row">
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Company Name*</label>
                  <input
                     type="text"
                     name="firstName"
                     className="form-control form-control-sm"
                     placeholder="Cellophane Square"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Company Email Address*</label>
                  <input
                     type="email"
                     className="form-control form-control-sm"
                     id="emial1"
                     placeholder="example@example.com.com"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Company Phone Number*</label>
                  <input
                     type="text"
                     name="phoneNumber"
                     className="form-control form-control-sm"
                     placeholder="(+1)408-657-9007"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">
                     Your position in Company*
                  </label>
                  <input
                     type="text"
                     name="place"
                     className="form-control form-control-sm"
                     required
                  />
               </div>
            </div>
         </div> */}
         
        </section>
    );
};

export default reduxForm({
    form: 'StepTwo', // a unique identifier for this form
})(StepTwo);
